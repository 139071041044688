<template>
  <div class="page container m-padded">
    <div class="columns">
      <div class="column left">
        <div id="hero-wrapper">
          <img src="./hero_b@2x.jpg" class="hero display-b"/>
          <img src="./hero_m@2x.jpg" class="hero display-m"/>
        </div>
      </div>

      <div ref="column-right" class="column right container b-padded">
        <div id="top-logos">
          <img src="./awg.png"/>
          <img src="./top-logo-a.svg"/>
        </div>

        <h1>The AWG timepiece is custom made for the Asymmetric Warfare Group. Designed and developed by MICROMILSPEC in Norway, and manufactured in Switzerland to the most exacting standards.</h1>

        <p class="faded">
          Developed through a collaboration between MICROMILSPEC and representatives from the Asymmetric Warfare Group, the AWG watch is part tribute, part functional precision instrument. A truly custom-made timepiece that is designed to last generations, with subtle hints to the history and spirit of the Asymmetric Warfare Group.
        </p>

        <p class="faded">
          In partnership with some of the leading Swiss manufacturers and specialists, the production of the AWG watch will commence once the pre-order period has ended. Via watchmaking methods refined over centuries, raw materials will be transformed into mechanical precision instruments, custom designed for the Asymmetric Warfare Group.
        </p>

        <p class="faded">
          The AWG watch will not be offered to the public, and is exclusively available to current and former members of the Asymmetric Warfare Group, or alternatively, by special invitation.
        </p>
        



      </div>
    </div>
    <FixedCTA/>
  </div>
</template>

<script>
import { KJOP_URL } from "../../data.js";
import RangeSequence from "../ui/RangeSequence";
import FixedCTA from "../ui/FixedCTA";

export default {
  data() {
    return {
      KJOP_URL,
      fbWidth: 310
    }
  },
  mounted() {
    window.addEventListener("resize", this.scaleFb);
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.scaleFb);
  },
  methods: {
    scaleFb() {
      //this doesn't work - but could it? The FB SDK rewrites many styles on mount
      this.fbWidth = this.$refs["column-right"].clientWidth;
    }
  },
  components: {RangeSequence, FixedCTA}
}
</script>

<style lang="scss" scoped>
@import "../../css/system";

p#swiss-made {
 margin-block-start: 3.2125rem;
 margin-block-end: 1.875rem;
}

.columns {
  display: flex;
  flex-direction: column;
  @include m {
    flex-direction: row;
  }
}

.column {
  display: flex;
  flex-direction: column;
  @include m {
    width: 50%;
    margin-right: 7.5rem;
    &:last-child {
      margin-right: 0;
    }
  }

}

#hero-wrapper {
  position: sticky;
  top: 7.182rem;
  .hero {
    width: 100%;
    @include m {
    }
  }
}

#top-logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2.8125rem;
  margin-bottom: 1.75rem;
  @include m {
    margin-top: 10.31rem;
  }
  img {
    height: 4.37rem;
    margin-right: 1.25rem;
  }
}


</style>
