<template>
  <div ref="frame" class="frame">
    <canvas ref="canvas" class="canvas"></canvas>
  </div>
</template>

<script>
import { ImageSequence } from "~/lib/ImageSequence.js";

export default {
  name: "Sequence",
  props: ["config", "time"],
  created() {
    this.imageSequence = new ImageSequence(this.config);
  },
  mounted() {
    this.imageSequence.mount(this.$refs.frame, this.$refs.canvas);
  },
  beforeDestroy() {
    this.imageSequence.destroy()
  },
  watch: {
    time: function(newTime) {
      this.imageSequence.setProgress(newTime);
    }
  }
}
</script>

<style lang="scss" scoped>
.ghost {
  position: relative;
}

.frame {
  width: 100%;
  position: relative;

  .canvas {
    position: absolute;
    left: 0px;
    transform-origin: 0% 0%;
  }
}
</style>
