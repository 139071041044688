<template>
  <section class="container">
    <div class="inner">
      <h1>
        Interactive view. <span class="faded">Move the slider to rotate.</span>
      </h1>
      <div id="quicklook-sequence">
        <RangeSequence left="Front" right="Back" v-bind:config="{
          root: '/front2back',
          length: 45,
          sizes: [
            ['large', 700, 783],
          ]}">
        </RangeSequence>
      </div>
    </div>
  </section>
</template>

<script>
import RangeSequence from "~/components/ui/RangeSequence"

export default {
  name: "InteractiveVisning",
  components: {RangeSequence}
}
</script>

<style lang="scss" scoped>
@import "~/css/system.scss";

section {
  margin-top: $box-gap;
  text-align: center;
  .inner {
    background-color: black;
    padding-top: .2rem;
    padding-bottom: 1.375rem;
    #quicklook-sequence {
      margin-block-start: 1.25rem;
    }

    h1 {
      padding: 0 1rem;
    }

    @include b-only {
      ::v-deep .sequence {
        @include CutoffLines;
      }
    }
  }
  @include m {
    text-align: left;
    .inner {
      padding: 0;
      position: relative;
      h1 {
        position: absolute;
        top: 2.25rem;
        left: 4.37rem;
        z-index: 1;
        width: 40%;
        margin: 0;
        padding: 0;
      }
      #quicklook-sequence {
        margin: 0 auto;
        width: 40%;
      }
      ::v-deep .toggle-timeline {
        position: relative;
        top: -2rem;
        margin-top: -2.95rem;
      }
    }
  }
}
</style>
