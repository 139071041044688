<template>
  <div ref="frame" class="scroll-width-box" :style="this.backgroundColor ? `background-color:${this.backgroundColor};` : ''">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ScrollWidthBox',

  props: {
    backgroundColor: {
      type: String,
      default: ''
    }
  },

  data: function() {
    return {time: 0}
  },
  mounted() {
    window.addEventListener("resize", this.update);
    document.addEventListener("scroll", this.update);
    this.update();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.update);
    document.removeEventListener("scroll", this.update);
  },
  methods: {
    update() {
      const total = window.innerHeight;
      const origin = window.innerHeight / 4;
      const range = total - origin;
      var rect = this.$refs.frame.getBoundingClientRect();
      const contentHeight = this.$refs.frame.clientHeight;
      const t = Math.max(0, Math.min(1, 1 - ((rect.top-contentHeight/3) - origin) / range*.3));

      this.time = t;
      this.$refs.frame.style.minWidth = `${t*100}%`;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../css/system.scss";

@include m {
  .scroll-width-box {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: black;

    .container {
      margin: none;
    }
  }
}
</style>
