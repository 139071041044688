<template>
  <div class="page container">
    <h1 id="copy">
      Frequently asked questions<br/>
      <span class="faded">
        Most of the answers to most of the questions.<br/><br/>
        Did you not find what you were looking for?<br/>
        Let us know at <a href="mailto:sos@micromilspec.com">sos@micromilspec.com</a>
      </span>
    </h1>
    <FaqAccordion :items="questions"/>
    <FixedCTA/>
  </div>
</template>

<script>
import Vue from "vue";
import FaqAccordion from "./FaqAccordion";
import FixedCTA from "../ui/FixedCTA.vue";
import { FAQ } from "../../data";

const questions = FAQ.map((x) => {
  return {title: x.title, value: x.value.replace(/(?:\r\n|\r|\n)/g, '<br/>')}
});

export default {
  name: 'Sporsmal',
  components: {
    FaqAccordion,
    FixedCTA
  },
  data() { return { questions } }
};
</script>

<style lang="scss" scoped>
@import "../../css/system.scss";

.page {
  display: flex;
  flex-direction: column;
}

#faq {
  @include m {
    max-width: 47rem;
    align-self: flex-end;
    margin-bottom: 5rem;
  }
}

#copy {
  margin-top: 4rem;
  margin-bottom: 4rem;
  a {
    color: $superlight-grey;
  }
  @include m {
    margin-top: 8.75rem;
    margin-bottom: 10.93rem;
  }
}
</style>
