<template>
<section id="faq" class="accordion" ref="rootEl">
  <div
    class="item"
    v-for="(item, i) in items"
    :key="`accordion-item-${i}`"
  >
    <div :class="generateQuestionClasses(i)" @click="makeActive(i)">
      <h2 class="title-text" v-html="item.title"></h2>
      <button :class="generateButtonClasses(i)"><img src="./arrow.svg"/></button>
    </div>
      <collapse-transition>
        <div v-if="i === activeQuestionIndex">
          <slot v-bind="item">
            <div class="value" v-html="item.value"></div>
          </slot>
        </div>
      </collapse-transition>
  </div>
</section>
</template>

<script>
import { CollapseTransition } from 'vue2-transitions'

export default {
  name: 'FaqAccordion',

  components: {
    CollapseTransition
  },

  data () {
    return {
      activeQuestionIndex: 0,
    }
  },

  props: {
    items: {
      type: Array,
      required: true
    },
  },

  methods: {
    makeActive (itemIndex) {
      this.activeQuestionIndex = this.activeQuestionIndex === itemIndex ? null : itemIndex
      this.$emit('itemSelect', { itemIndex })
    },
    generateButtonClasses (buttonIndex) {
      return [
        'toggle-button',
        this.activeQuestionIndex === buttonIndex
          ? 'toggle-button_active'
          : null
      ]
    },
    generateQuestionClasses (questionIndex) {
      return [
        'title',
        this.activeQuestionIndex === questionIndex
          ? 'title_active'
          : null
      ]
    },
  },
}
</script>

<style lang="scss" scoped>

$active-color: white;
$border-color: grey;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background: none;
  outline: none;
}

#faq {
  width: 100%;
}

.accordion {
  margin-top: 15px;

  .item {
    border-bottom: 2px solid #464646;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;

    padding: 1.5rem 0;

    color: white;

    &_active {
      color: $active-color;
    }

    &:hover {
      color: $active-color;

      .accordion__toggle-button {
        &::before,
        &::after {
          background: $active-color;
        }
      }
    }

    &-text {
      margin-right: 10px;
      line-height: 1.5;
    }
  }

  .value {
    padding: 1rem 0 2.8rem 0;
    text-align: left;
  }

  .toggle-button {
    position: relative;
    transition: all 0.3s;
    transform-origin: 75% 75%;
    padding-left: 16px;
    cursor: pointer;

    &_active {
      transform: rotate(180deg);
    }
  }
}
</style>
