<template>
  <div id="header">
    <div id="flash-outer">
      <router-link to="/sporsmal" id="flash" class="container">
        <div class="first">Split payment from $155/mo <img class="check-icon" src="./check-icon.svg"/></div>
        <div>Frequently asked questions <img class="chevre-icon" src="./chevre-icon.svg"/></div>
      </router-link>
    </div>
    <div id="top-bar-outer">
      <div id="top-bar" class="container">
        <router-link to="/" id="logo"><img src="../logo-icon.svg"/></router-link>
        <nav>
          <router-link to="/" exact>The watch</router-link>
          <router-link to="/spesifikasjoner">Specifications</router-link>
          <router-link to="/prosjektet">The project</router-link>
          <router-link class="display-m" to="/sporsmal">FAQ</router-link>
          <!--
          <router-link to="/kjop">Buy</router-link>
          -->
          <a class="primary" :href="KJOP_URL">Buy</a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { KJOP_URL } from "~/data.js";

export default {
  data() {
    return { KJOP_URL }
  }
}
</script>

<style lang="scss" scoped>
@import "~/css/system";

#header {
  z-index: 9999;
  position: relative;
}

#flash-outer {
  background-color: #202020;
  padding: 0.25rem 0;
}

#flash {
  font-family: Karelia;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  div.first {
    flex: 1;
  }
  .check-icon {
    position: relative;
    top: 3px;
    padding-left: .25rem;
  }
  .chevre-icon {
    padding-left: .25rem;
  }
}

#top-bar-outer {
  background-color: $middle-grey;
}

#top-bar {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  @include m {
    width: 100%;
    max-width: 1100px;
  }
}

#logo {
  display: flex;
  align-items: center;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  a {
    margin-left: 1.1875rem;
    &:first-child {
      margin-left: 0rem;
    }
    &.primary {
      color: $yellow;
    }
    margin-top: 16px;
    padding-bottom: 15px;
    border-bottom: 3px solid rgba(0,0,0,0);
    text-decoration: none;

    @include TextM12;
    @include m {
      @include TextD14;
    }
  }
  a.router-link-active {
    border-bottom: 3px solid white;
  }
}
</style>
