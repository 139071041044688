<template>
  <Gallery>
    <div v-for="image in images" class="slide">
      <picture>
        <source :srcset="image.desktop" media="(min-width: 42rem)">
        <img :src="image.mobile">
      </picture>
    </div>
  </Gallery>
</template>

<script>
import Gallery from "~/components/ui/Gallery/Gallery";
import { sortBy, values, mergeWith, clone } from "lodash";
import mobileImages from "./*-mobile.jpg";
import desktopImages from "./*-desktop.jpg";

const images = sortBy(values(mergeWith(clone(mobileImages), desktopImages, (a, b) => {
  return {mobile: a ? a : b, desktop: b ? b : a};
})), x => x.mobile);

export default {
  name: 'TopGallery',
  data() {
    return {images};
  },
  components: { Gallery }
}
</script>
