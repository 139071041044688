<template>
  <section class="container">
    <div>
      <PositionSequence v-bind:config="{
        root: '/explode',
        length: 80,
        sizes: [
          ['small', 600, 1432],
          ['large', 1400, 587],
        ]}">
      </PositionSequence>
    </div>

    <div class="copy">
      <h1>A mechanical timepiece made to last generations.</h1>

      <p class="faded last">
        No batteries, no integrated circuits, no corners cut. The AWG is a robust Swiss Made timepiece powered by a tried and proven mechanical movement. Manufactured in Switzerland, and serviceable in all foreseeable future.
      </p>
    </div>
  </section>
</template>

<script>
import PositionSequence from "~/components/ui/PositionSequence"

export default {
  name: "ExplodeSequence",
  components: {PositionSequence}
}
</script>

<style lang="scss" scoped>
@import "~/css/system.scss";

section {
  background-color: black;
  //text-align: center;
  margin-top: $box-gap;

  p.last {
    margin-block-end: 0rem;
    padding-bottom: 2.4375rem;
  }

  @include m {
    padding-top: 4.3125rem;
    margin-top: 0.625rem;


    .copy {
      width: 86%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      @include TextM22;
    }

    h1 {
      margin-block-end: 1.8125rem;
    }

    p.last {
      margin-block-start: 2rem;
      padding-bottom: 4.75rem;
    }
  }

  h1 {
    margin-block-start: 0rem;
    @include TextM30;
  }
}
</style>
