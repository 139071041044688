<template>
  <div ref="frame" :id="id" class="position-opacity">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PositionOpacity",
  props: ["id"],
  mounted() {
    window.addEventListener("resize", this.update);
    document.addEventListener("scroll", this.update);
    this.update();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.update);
    document.removeEventListener("scroll", this.update);
  },
  methods: {
    update() {
      const height = this.$refs.frame.clientHeight;
      const t = (height - window.pageYOffset) / height;
      const opacity = Math.max(.23, Math.min(1, t));
      this.$refs.frame.style.opacity = opacity;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
