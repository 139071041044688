export const KLOKKEMODELL = "AWG watch";
export const URVERK = "Sellita";
export const GANGRESERVETIMER = 42;
export const SAMARBEIDSPARTNER = "Asymmetric Warfare Group"
export const PRICE = "$930"
export const DELBETALING_PRICE = "$155/mo";
export const KJOP_URL = "https://checkout.micromilspec.com/9b624e90-9829-4355-8296-ba1a14b85ba2";

export const FAQ = [
  {
    title: `Who can order the watch?`,
    value: `The ${KLOKKEMODELL} watch is exclusively available to current and former members of the Asymmetric Warfare Group, or alternatively, by special invitation.`,
  },
  {
    title: `Why does the watch cost ${PRICE}?`,
    value: `The ${KLOKKEMODELL} is a custom watch designed solely for the Asymmetric Warfare Group. Many of the components are developed and manufactured exclusively for this project. All parts are sourced or produced by leading Swiss manufacturers and specialists.

    After final assembly each watch goes through a stringent set of tests and quality control. Not only do we feel confident about the ${KLOKKEMODELL} being on par with the competition – we truly believe that our watches far exceed the performance and quality typically associated with our category.`,
  },
  {
    title: `Do you offer payment installments?`,
    value: `Yes, and we offer payment intervals of 2 or 6 months.`,
  },
  {
    title: `What do you mean by “Swiss Made”?`,
    value: `All of our watches are manufactured in compliance with the “Swiss Made” ordinance as defined by the Swiss Federal Council.

    To put it briefly, a minimum of 60 percent of the watch is produced in Switzerland, including key processes such as final assembly and inspection. This ensures that every watch bearing the “Swiss Made” designation, delivers the high quality one might expect from a Swiss timepiece.`,
  },
  {
    title: `What do you mean by “mechanical” and “self-winding”?`,
    value: `A mechanical movement sources its power from a wound mainspring – no batteries or electronics.

    “Self-winding” (or “automatic”) means that the mainspring is wound by the movement of the user’s wrist, thus eliminating the need to manually wind it by turning the crown.`,
  },
  {
    title: `When will I receive my watch?`,
    value: `Estimated production time and delivery is set to minimum 24 weeks. Due to the COVID-19 pandemic, delays of up to 60 days may occur.`,
  },
  {
    title: `What is covered by the 5-year warranty?`,
    value: `Our warranty covers all material and manufacturing defects – including internal and external components – existing at the time of delivery of your MICROMILSPEC watch.

    Put briefly – if it is our fault, we will fix, repair, or replace your watch free of charge.

    Normal wear and tear, user induced scratches and damage – including water damage and theft – is not covered by the warranty.

    Please note that we recommend an annual pressure test to ensure that the watch is water resistant. Not following this recommendation may void your warranty concerning water damage.`,
  },
  {
    title: `How often should I wind the watch?`,
    value: `You may wind the watch manually by carefully turning the crown in a clockwise motion. A slight change of friction signals that the movement is fully wound.

    However, the watch will also wind itself when actively worn. A full power reserve is typically achieved after 4 to 5 hours of use.`,
  },
  {
    title: `How often do I need to change the battery?`,
    value: `Your watch is based on a self-winding mechanical movement, and does not contain any batteries or electrical components.`,
  },
  {
    title: `Where can I have my watch serviced and how much does it cost?`,
    value: `Like cars, all mechanical watches should be cleaned, oiled, adjusted and serviced on a regular basis. If properly, the ${KLOKKEMODELL} may last generations.

    Our recommended service interval is every 5 years. Service cost may vary by region, but is typically in the range of $300 to 500. We also offer full service via our MICROMILSPEC authorized service center, at a cost of only $250 excl. shipping.`,
  },
  {
    title: `Can my watch handle extreme weather and shocks?`,
    value: `Yes, to some degree. While the ${KLOKKEMODELL} is a robust high quality product, both internal and external components may be damaged if not handled correctly. Use it – but don’t abuse it!

    You should never throw, hit or drop your watch, and also avoid sudden temperature changes like going straight from the sauna to ice cold water may cause condensation or water damage.`,
  },
  {
    title: `How do I maintain my watch?`,
    value: `You will receive, or have already received, an e-mail containing a link to our detailed tips for use and maintenance of your watch.`,
  },
  {
    title: `Can I polish my watch?`,
    value: `Yes, but we don’t recommend it. While you may polish your ${KLOKKEMODELL} at your own discretion, the gray PVD-coating will eventually be discolored or disappear completely.`,
  }
];
