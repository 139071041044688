<template>
<div ref="frame" class="gallery">
  <div ref="images" class="images">
    <slot></slot>
  </div>
  <div ref="overlay" class="overlay">
    <div class="container">
      <div class="nav">
        <div v-for="(m,i) in numImages" class="marker" :style="markerStyles[i]"></div>
      </div>
      <div class="arrows">
        <picture ref="left-arrow" class="arrow left" >
          <source srcset="./left-arrow.svg" media="(min-width: 42rem)">
          <img src="./left-arrow-mobile.svg">
        </picture>
        <div class="spacer"></div>
        <picture ref="right-arrow" class="arrow right">
          <source srcset="./right-arrow.svg" media="(min-width: 42rem)">
          <img src="./right-arrow-mobile.svg">
        </picture>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Hammer from "hammerjs";

const AUTOCYCLE_STEP_COUNT = 100;
const AUTOCYCLE_DWELL_SECONDS = 4;
const AUTOCYCLE_STEP = 1 / AUTOCYCLE_STEP_COUNT;
const AUTOCYCLE_TIMEOUT = AUTOCYCLE_DWELL_SECONDS * 1000 / AUTOCYCLE_STEP_COUNT;
const AUTOCYCLE_MARKER_OPACITY = .7;

export default {
  name: "Gallery",
  data() {
    return {
      numImages: 0,
      idx: 0,
      cycleT: 0,
    }
  },
  mounted() {
    this.numImages = this.$refs.images.childElementCount;
    this.hammer = new Hammer(this.$refs.frame);
    this.hammer.on("swipeleft swiperight tap press", this.onGesture);
    this.move();
    window.addEventListener("resize", this.update);
    document.addEventListener("scroll", this.update);
    this.update();
    this.tickAutoCycle();
  },
  beforeDestroy() {
    this.hammer.destroy();
    this.cancelAutoCycle();
    window.removeEventListener("resize", this.update);
    document.removeEventListener("scroll", this.update);
  },
  computed: {
    markerStyles: function() {
      return Array(this.numImages).fill(0).map((_,i) => {
        let t = 0;
        if (i === this.idx) {
          t = this.cycleT;
        }
        return `background-image: linear-gradient(to right, rgba(255,255,255,1) 0% ${t*100}%, rgba(255,255,255,.3) ${t*100}%);`;
      });
    }
  },
  methods: {
    update() {
      const height = this.$refs.frame.clientHeight;
      const t = (height - window.pageYOffset) / height;
      if (t < 1) {
        this.cancelAutoCycle();
      }

      const frameOpacity = Math.max(.23, Math.min(1, t));

      this.$refs.overlay.style.opacity = Math.max(0, Math.min(1, Math.pow(t, 5)));
      this.$refs.frame.style.opacity = frameOpacity;
    },
    tickAutoCycle() {
      this.cycleT += AUTOCYCLE_STEP;
      if (this.cycleT >= 1) {
        this.cycleT = 0;
        this.next();
      }
      this.timeout = setTimeout(this.tickAutoCycle, AUTOCYCLE_TIMEOUT);
    },
    cancelAutoCycle() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
        this.cycleT = 1;
      }
    },
    onGesture(e) {
      this.cancelAutoCycle();
      switch (e.type) {
        case "swipeleft":
          this.next();
          break;
        case "swiperight":
          this.prev();
          break;
        case "tap":
        case "press":
          if (e.center.x < this.$refs.frame.clientWidth / 3) {
            this.prev();
          } else if (e.center.x > this.$refs.frame.clientWidth * 2/3) {
            this.next();
          }
          break;
      }
    },
    next() {
      this.idx = (this.idx + 1) % this.numImages;
      this.move();
    },
    prev() {
      this.idx = (this.numImages + (this.idx - 1)) % this.numImages;
      this.move();
    },
    move() {
      const images = this.$refs.images.children;
      const left = images[this.idx].offsetLeft;

      this.$refs.images.style.transform = `translate(${-left}px)`;
      this.$refs["left-arrow"].style.opacity = this.idx > 0 ? 1 : 0;
      this.$refs["right-arrow"].style.opacity = this.idx < this.numImages - 1 ? 1 : 0;
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
@import "~/css/system.scss";

.gallery {
  position: relative;
  overflow: hidden;

  .images {
    position: relative;
    display: flex;
    flex-direction: row;
    img {
      width: 100vw;
    }
    transition: transform .4s ease-out;
  }

  .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    .container {
      position: relative;
      height: 100%;
    }

    .nav {
      position: absolute;
      bottom: 3rem;
      left: 1.25rem;
      right: 1.25rem;
      display: flex;
      flex-direction: column;

      justify-content: center;

      display: flex;
      flex-direction: row;

      .marker {
        width: 26px;
        height: 3.02px;
        border-radius: 6px;
        margin-right: 0.3125rem;
      }
    }

    .arrows {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: row;
      align-items: center;

      .arrow {
        opacity: 0;
        display: block;
        transition: opacity .2s ease-in;
      }

      .spacer {
        flex: 1;
      }
    }
  }
}
</style>
