<template>
  <div>
    <TopBar/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>


<script lang="js">
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import ToggleTimeline from "./ui/ToggleTimeline/ToggleTimeline";
import Sequence from "./ui/Sequence";
import RangeSequence from "./ui/RangeSequence";
import EmailForm from "./ui/EmailForm";
import EngravingPreview from "./ui/EngravingPreview";
import PositionSequence from "./ui/PositionSequence";
import Gallery from "./ui/Gallery/Gallery";
import FixedCTA from "./ui/FixedCTA";
import ScrollWidthBox from "./ui/ScrollWidthBox";
import MobileScrollWidthBox from "./ui/MobileScrollWidthBox";
import PositionOpacity from "./ui/PositionOpacity";
import KjopLink from "./ui/KjopLink";

import TopBar from "./layout/TopBar/TopBar";
import Footer from "./layout/Footer/Footer";

import Prosjektet from "./Prosjektet/Prosjektet";
import Klokken from "./Klokken/Klokken";
import Spesifikasjoner from "./Spesifikasjoner/Spesifikasjoner";
import Sporsmal from "./Sporsmal/Sporsmal";
import OmOss from "./OmOss/OmOss";

const routes = [
  {path: '/', component: Klokken},
  {path: '/spesifikasjoner', component: Spesifikasjoner},
  {path: '/prosjektet', component: Prosjektet},
  {path: '/sporsmal', component: Sporsmal},
  {path: '/om-oss', component: OmOss},
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default Vue.extend({
  router,
  components: { KjopLink, PositionOpacity, MobileScrollWidthBox, ScrollWidthBox, FixedCTA, Gallery, TopBar, Footer, ToggleTimeline, Sequence, RangeSequence, EmailForm, OmOss, EngravingPreview, PositionSequence },
});
</script>
